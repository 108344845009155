
import { Component, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { StoreModule } from "@/store/types";

import { EventBus } from "@/events/index";
import { GlobalActions, GlobalGetters } from "@/store/modules/global/types";
import AuthService from "@/services/auth_service";
import { AuthError } from "@/services/error_service";

@Component
export default class Auth extends Vue {
  @(namespace(StoreModule.Global).Getter(GlobalGetters.GetLoggedIn))
  isLoggedIn!: boolean;
  @(namespace(StoreModule.Global).Action(GlobalActions.AddLoggedIn))
  addLoggedIn: any;
  @(namespace(StoreModule.Global).Action(GlobalActions.AddBusinessUnit))
  setBusinessUnit: any;
  protected authService = new AuthService();
  protected toast = false;
  protected messageHtml = "";
  protected className = "success";

  /**
   * Trigger redirect to c5 login if change in isLoggedIn value in store changes to false
   */
  @Watch("isLoggedIn")
  onAuthChange(): void {
    if (!this.isLoggedIn) {
      const url = encodeURI(process.env.VUE_APP_AUTH_URL);
      EventBus.$emit(
        "showError",
        `Your session has expired. Please <a href='https://beta.ai.mcgit.cc/oauth/2.0/authorize?response_type=code&state=&client_id=17c13818da83af5917655fb0958c9ecf8abda6dc8b9158ef6e09876fcd3a0b55&scope=account%3Aread&redirect_uri=${url}'>click here to login again.</a> You will be automatically redirected in 5 seconds. `
      );
      setTimeout(function() {
        window.location.href =
          "https://beta.ai.mcgit.cc/oauth/2.0/authorize?response_type=code&state=&client_id=17c13818da83af5917655fb0958c9ecf8abda6dc8b9158ef6e09876fcd3a0b55&scope=account%3Aread&redirect_uri=" +
          url;
      }, 7000);
    }
  }

  /** If isLoggedIn store value is false, redirect to c5 login
   * If code query is in url, send login request
   * Otherwise user is logged in and no action is needed
   */
  render() {
    if (this.$route.query.code && !this.isLoggedIn) {
      this.setBusinessUnit(null);
      this.login();
    } else {
      this.checkAuth();
    }
  }

  /**
   * Login API request
   * Sets store value isLoggedIn to true on success
   * On false, shows error toast & triggers redirect
   */
  protected async login(): Promise<void> {
    try {
      await this.authService.login(this.$route.query.code as string);
      this.addLoggedIn(true);
    } catch (err) {
      if (err instanceof AuthError) {
        // const url = encodeURI("http://localhost:8080/");
        const url = encodeURI(process.env.VUE_APP_AUTH_URL);
        EventBus.$emit(
          "showError",
          `Login failed. Please <a href='https://beta.ai.mcgit.cc/oauth/2.0/authorize?response_type=code&state=&client_id=17c13818da83af5917655fb0958c9ecf8abda6dc8b9158ef6e09876fcd3a0b55&scope=account%3Aread&redirect_uri=${url}'>click here to try again.</a> You will be automatically redirected in 5 seconds. `
        );
        setTimeout(function() {
          window.location.href =
            "https://beta.ai.mcgit.cc/oauth/2.0/authorize?response_type=code&state=&client_id=17c13818da83af5917655fb0958c9ecf8abda6dc8b9158ef6e09876fcd3a0b55&scope=account%3Aread&redirect_uri=" +
            url;
        }, 7000);
      }
    }
  }

  protected async checkAuth(): Promise<void> {
    const url = encodeURI(process.env.VUE_APP_AUTH_URL);
    try {
      await this.authService.checkAuth();
      this.addLoggedIn(true);
    } catch (err) {
      this.addLoggedIn(false);
      window.location.href =
        "https://beta.ai.mcgit.cc/oauth/2.0/authorize?response_type=code&state=&client_id=17c13818da83af5917655fb0958c9ecf8abda6dc8b9158ef6e09876fcd3a0b55&scope=account%3Aread&redirect_uri=" +
        url;
    }
  }
}

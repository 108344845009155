
import { namespace } from "vuex-class";
import { StoreModule } from "@/store/types";
import { GlobalGetters } from "@/store/modules/global/types";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class Menu extends Vue {
  @(namespace(StoreModule.Global).Getter(GlobalGetters.GetBusinessUnit))
  businessUnit!: string;
}


import { Component, Vue } from "vue-property-decorator";
import Icon from "@/components/reusable/Icon.vue";

@Component({
  components: {
    Icon,
  },
})
export default class SwitchBrand extends Vue {
  public data: Array<{ key: string; value: string }> = [
    {
      key: "",
      value: "Select your business",
    },
    {
      key: "bmh",
      value: "Airmatic",
    },
    {
      key: "workmaster",
      value: "Workmaster",
    },
  ];

  public bu = "";

  public submit(): void {
    if (this.bu) {
      this.$emit("onSelectBU", this.bu);
    }
  }
}


import { Component, Vue, Watch } from "vue-property-decorator";
import Menu from "@/views/Menu.vue";
import Icon from "@/components/reusable/Icon.vue";
import { namespace } from "vuex-class";
import { StoreModule } from "@/store/types";
import { EventBus } from "@/events/index";
import Auth from "@/components/Auth.vue";
import { GlobalGetters, GlobalActions } from "@/store/modules/global/types";
import SwitchBrand from "@/components/reusable/modals/SwitchBrand.vue";

import UIkit from "uikit";

@Component({
  components: {
    Menu,
    Icon,
    Auth,
    SwitchBrand,
  },
})
export default class Main extends Vue {
  @(namespace(StoreModule.Global).Getter(GlobalGetters.GetLoading))
  isLoading!: boolean;
  @(namespace(StoreModule.Global).Action(GlobalActions.AddLoading))
  setLoading: any;

  @(namespace(StoreModule.Global).Getter(GlobalGetters.GetBusinessUnit))
  businessUnit!: string;
  @(namespace(StoreModule.Global).Action(GlobalActions.AddBusinessUnit))
  setBusinessUnit: any;

  public messageHtmlFail = "";
  public messageHtmlSuccess = "";
  public messageHtmlError = "";
  public showSpinner = false;
  public showSuccess = false;
  public showFail = false;
  public showError = false;
  public success: string[] = [];
  public failed: string[] = [];
  public conflicted: string[] = [];

  get isBMH(): boolean {
    return this.businessUnit === "bmh";
  }

  get isWorkmaster(): boolean {
    return this.businessUnit === "workmaster";
  }

  get logoURL(): string {
    return this.isBMH
      ? "https://airmatic.b-cdn.net/assets/ui/airmatic_logo.png"
      : "https://airmatic.b-cdn.net/assets/ui/workmaster_logo.png";
  }

  @Watch("$route.query", { deep: true, immediate: true })
  protected onQueryChange() {
    this.$nextTick(() => {
      if (this.$route.query.deleted) {
        EventBus.$emit(
          "showSuccess",
          `${decodeURIComponent(this.$route.query.deleted as string)} ${this.$route.query.type ? this.$route.query.type : "item"
          } deleted successfully.`,
          []
        );
      } else if (this.$route.query.created) {
        EventBus.$emit(
          "showSuccess",
          `${decodeURIComponent(this.$route.query.created as string)} ${this.$route.query.type ? this.$route.query.type : "item"
          } created successfully.`,
          []
        );
      }
    });
  }

  created(): void {
    EventBus.$on("showError", (errorMsg: string): void => {
      this.messageHtmlError = errorMsg;
      this.showError = true;
    });
  }

  /**
   * "showError" global event
   * Triggers error toast
   * This is a global event, so is called from any & all error events within the app to display errors
   *
   * @param errorMsg message caught from API services. It is either string or HTML as string
   */
  mounted(): void {
    EventBus.$on("showError", (errorMsg: string): void => {
      this.messageHtmlError = errorMsg;
      this.showError = true;
      this.setLoading(false);
    });
    EventBus.$on(
      "showFail",
      (msg: string, data: string[], conflicted?: string[]): void => {
        this.messageHtmlFail = msg;
        this.failed = data;
        if (conflicted) {
          this.conflicted = conflicted;
        }
        this.showFail = true;
      }
    );
    EventBus.$on("showSuccess", (msg: string, data: string[]): void => {
      this.messageHtmlSuccess = msg;
      this.success = data;
      this.showSuccess = true;
    });
    EventBus.$on("showNotification", (html: string): void => {
      UIkit.notification(html);
    });

    if (!this.businessUnit) {
      this.showSwitchBrand();
    }
  }

  beforeDestroy() {
    EventBus.$off("showError");
    EventBus.$off("showFail");
    EventBus.$off("showSuccess");
    EventBus.$off("showNotification");
  }

  public closeToastError(): void {
    this.showError = false;
  }

  public closeToastFail(): void {
    this.conflicted = [];
    this.failed = [];
    this.showFail = false;
  }

  public closeToastSuccess(): void {
    this.success = [];
    this.showSuccess = false;
  }

  public async selectBU(value: string) {
    this.setBusinessUnit(value);
    UIkit.modal("#switch-brand-modal.uk-open").hide();
    if (this.$route.path !== '/') {
      await this.$router.push('/');
    }
  }

  public showSwitchBrand(): void {
    UIkit.modal("#switch-brand-modal").show();
  }
}
